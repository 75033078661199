/////////////////////////////////////
// Variables
/////////////////////////////////////
$tablet-width-portrait: 1023px;
$mobile-width-portrait: 479px;
$max-mobile-height: 700px;

/////////////////////////////////////
// Colours
/////////////////////////////////////
$color-brand-1: #E3000E;
$color-brand-1-mild: rgb(255,188, 192);
$color-brand-1-light: rgb(255,207,210);

$color-brand-2: #6FB655;
$color-brand-2-mild: #39723F;


$color-standard-1: #929DB1; //Dark grayish blue
$color-background-support-1: #dbdfe6; //Light grayish blue

$primary-selected-color: #1976D2;

/////////////////////////////////////
// Fonts
/////////////////////////////////////
$font-bold: Tenant-Bold, Courier, Helvetica, sans-serif;
$font-standard: Tenant-Regular, Courier, Helvetica, sans-serif;
$font-light: Tenant-Light, Courier, Helvetica, sans-serif;

$h1-font-size: 24px; 
$h1-font-weight: 400;
$h1-font-family: $font-bold;

$h2-font-size: 18px;
$h2-font-weight: 400;
$h2-font-family: $font-bold;

$text-info-font-size: 17px;
$text-info-bold-font-size: 17px;
$text-info-bold-font-family: $font-bold;

/////////////////////////////////////
// Buttons
/////////////////////////////////////
$button-Border: 1px solid; 
$button-border-radius: 12px;
$button-font-size: 18px; 
$button-font-family: $font-bold;
$button-Padding: 13px 30px;

$button-boxshadow-hover-1: $color-brand-1-light;
$button-boxshadow-hover-2: $color-brand-1-mild;

$button-action-1-background-color: $color-brand-1;
$button-action-1-border-color: $color-brand-1;
$button-action-1-color: #fff;
$button-action-1-color-hover: #fff;
$button-action-1-disabled: $color-brand-1-light;

$button-action-2-background-color: #fff;
$button-action-2-border-color: $color-brand-1;
$button-action-2-color: $color-brand-1;
$button-action-2-color-hover: $color-brand-1;

// Navigation menu-items button
$button-menu-cat-background-color: #cecece; 
$button-menu-cat-color: #000;
$button-menu-cat-color-hover: $button-menu-cat-color;

$button-menu-cat-active-background-color: #fff;
$button-menu-cat-active-color: $color-brand-1;
$button-menu-cat-active-color-hover: $color-brand-1;

/////////////////////////////////////
// Page Specific
/////////////////////////////////////

// Body
$body-container-background: #fff; 

//Basket-Time-Picker
$time-picker-selected-color: #fff; 
$time-picker-selected-background: $color-brand-1;

//Checkout
$checkout-address-tile-box-shadow: 0 0 25px 1px rgba(53, 53, 53, 0.25); //Very dark gray.
$checkout-address-tile-hover: $color-brand-1-light;

//Deals
$deals-product-tile-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 2px 0 rgba(0,0,0,.14), 0 0 5px 0 rgba(0,0,0,.12);
$deals-product-tile-border: 1px solid #e8e8e8; //Very light gray.

//Menu
$menu-product-tile-box-shadow: 0 0 25px 1px rgba(53, 53, 53, 0.25); //Very dark gray.
$menu-product-tile-border: 1px solid #e8e8e8; //Very light gray.

$menu-product-tile-price-radius: 0 6px 6px 0;
$menu-product-tile-add-radius: 6px 0px 0px 6px;
$menu-image-holder-min-width: 328px;
$menu-image-holder-min-height: 185px;

//Navigation
$nav-cart-total-size: 34px;
$nav-mobile-menu-username-background: #3f4858; //Very dark grayish blue.

//Home
$home-postcode-input-container-radius: 20px; 

//Profile
$profile-details-container: 1px solid #e8e8e8; //Very light gray.

// Toasts
$toast-border-radius: 6px;

////////////////////////////////////////////////
/// ANDROWEB: DEFAULT THEME
////////////////////////////////////////////////
$basket-background-colour: #EBEEF6;
$basket-content-items-border: #e8e8e8;
$basket-even-item-backgroud: rgba( 232, 232, 232, 0.45);
$basket-icons: #929DB1;
$basket-icons-hover: #000;
$basket-total-background: #e8e8e8;

$checkout-tiles-background: #fff;
$checkout-tiles-radius: 12px;

$deals-product-tile-background: #fff;
$deals-product-tile-radius: 6px;
$deals-line-item-border-radius: 6px;
$deals-line-item-selected-background: #D5E8D4;
$deals-line-item-selected-colour: #026700;

$menu-add-to-cart-background: #3f4858;
$menu-add-to-cart-color: #fff;
$menu-nav-header-background: #dbdfe6;
$menu-nav-search-icon-colour: #000;
$menu-product-tile-background: #fff;
$menu-product-tile-radius: 6px;

$nav-cart-total-color: #ff1e1e;
$nav-tool-tips-background: #3f4858;
$nav-tool-tips-colour: #fff;

$profile-address-cards-background-colour: #fff;
$profile-icon-hover: #e70095;
$profile-nav-header-background: #dbdfe6;
$profile-settings-tile-background: #fff;
$profile-tiles-radius: 12px;
$profile-tool-tips-background: #fff;
$profile-tool-tips-colour: #000;

$selected-order-background: #cecece;
$selected-order-body-background: #dbdfe6;
$selected-order-body-colour: #000;

:root {
    --footer-background-colour: #333333;
    --footer-link-colour: #fff;
    --nav-bar-background-colour: #fff;
    --product-content-padding: 0 10px;
}
