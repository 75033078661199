@import 'font-imports.scss';
@import 'styles-config.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/ngx-toastr/toastr.css';

@import '../node_modules/slick-carousel/slick/slick.scss';
@import '../node_modules/slick-carousel/slick/slick-theme.scss';

/////////////////////////////////////
// Body
/////////////////////////////////////

*:focus, *:active {
  outline: 0 !important;
}

.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}

* {
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;
}

a {
  text-decoration: none;
}

html,
body {
  background-color: $body-container-background;
  border: 0;
  font-family: $font-light !important;
}

.disable-select {
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.siteContainer {
  background-color: $body-container-background;
  display: flex;
  flex-flow: column;
  min-height: 100%;
  height: 100%;

  .header {
    height: 90px;
  }

  &.on-home .header {
    height: 150px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    position: relative;
  }
}

.block {
  opacity: 0.5;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.lds-dual-ring {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.lds-dual-ring:after {
  animation: lds-dual-ring 0.8s linear infinite;
  border: 5px solid #fff;
  border-color: $color-brand-1 transparent $color-brand-1 transparent;
  border-radius: 50%;
  content: " ";
  display: block;
  height: 30px;
  margin: 1px;
  width: 30px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.order-summary-row {
  justify-content: space-between;
  align-items: center;
  height: 42px;
  min-height: 42px;
  display: flex;
  background-color: $color-brand-2-mild;
  padding: 0 8px;
  color: #fff;
}

.order-summary-row h2 {
  margin-bottom: 0;
}

.mat-mdc-unelevated-button>.mat-icon {
  margin-right: 4px !important;
}

.hidden {
  display: none !important;
}

.content-with-footer {
  @extend .flex-column;
  padding: 20px;
  flex: 1;
}

.arrow-back-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  .mat-icon {
    margin-right: 5px;
    margin-top: -2px;
  }
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background-color: #E5E5E5;
}

.site-card-accordion {
  span.isOpen {
    color: green;
  }
  
  span.isClosed {
    color: red;
  }
}

.alert-message {
  color: red;
}

.standard-hyperlink {
  text-decoration: underline;
  color: $primary-selected-color;
  cursor: pointer;
}

/////////////////////////////////////
// Icon
/////////////////////////////////////

.svg-inline--fa {
  vertical-align: -1px;
}

/////////////////////////////////////
// Text
/////////////////////////////////////

H1 {
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
  font-family: $h1-font-family;
}

H2 {
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
  font-family: $h2-font-family;
}

.text-info-label {
  font-size: $text-info-font-size;
  font-family: $font-standard;
}

.text-info-label-bold {
  font-size: $text-info-bold-font-size;
  font-family: $text-info-bold-font-family;
}

.text-color-branded {
  color: $color-brand-1;
}

.red-alert-text {
  color: red;
}

/////////////////////////////////////
// Buttons and menus
/////////////////////////////////////
.button {
  @extend .disable-select;
  font-size: $button-font-size;
  font-family: $button-font-family;
  Padding: $button-Padding;
  Border: $button-Border;
  border-radius: $button-border-radius;
  cursor: Pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &-action-1 {
    @extend .button;
    border-color: $button-action-1-border-color;
    background-color: $button-action-1-background-color;
    color: $button-action-1-color;

    &.mat-mdc-raised-button:not(:disabled) {
      border-color: $button-action-1-border-color;
      background-color: $button-action-1-background-color;
      color: $button-action-1-color;
    }

    &:hover {
      color: $button-action-1-color-hover;
    }
  }

  &-action-1.mat-mdc-raised-button {
    @extend .button;
    border-color: $button-action-1-border-color;
    background-color: $button-action-1-background-color;
    color: $button-action-1-color;
    height: 48px;

    &.mat-mdc-raised-button:not(:disabled) {
      border-color: $button-action-1-border-color;
      background-color: $button-action-1-background-color;
      color: $button-action-1-color;
    }

    &:hover {
      color: $button-action-1-color-hover;
    }
  }

  &-action-2 {
    @extend .button;
    border-color: $button-action-2-border-color;
    background-color: $button-action-2-background-color;
    color: $button-action-2-color;

    &:hover {
      color: $button-action-2-color-hover;
    }
  }

  &-menu-cat {
    @extend .button;
    border: 0;
    background-color: $button-menu-cat-background-color;
    color: $button-menu-cat-color;

    &:hover {
      color: $button-menu-cat-color-hover;
    }

    &.active {
      background-color: $button-menu-cat-active-background-color;
      color: $button-menu-cat-active-color;

      &:hover {
        color: $button-menu-cat-active-color-hover;
      }
    }
  }

  &-action-disabled {
    @extend .button;
    color: #fff !important;
    cursor: default !important;
    background-color: $button-action-1-disabled !important;
    border: none;
    opacity: 0.8;
  }
}

.button-action-mat {
  border-color: $button-action-1-border-color !important;
  font-size: $button-font-size !important;
  Padding: $button-Padding !important;
  Border: $button-Border !important;
  border-radius: $button-border-radius !important;
}

app-menu, app-product, app-thank-you {
  height: 100%;
}

app-custom-sections, app-locations, app-location {
  height: 100%;
  display: flex;
  flex-direction: column;
}

app-table-number {
  width: 100%;
  height: 100%;
  display: flex;
}

.button-action-outlined {
  width: 110px;
  text-transform: uppercase;
  border-width: 2px !important;
  border-radius: 20px !important;
  line-height: initial !important;
}

/////////////////////////////////////
// Modals
/////////////////////////////////////
body.modal-open {
  overflow: hidden !important;
  padding-right: 0px !important;
  pointer-events: all;
  position: fixed;
  width: 100%;
}

.modal-open .modal {
  z-index: 9999999;
}

.order-wanted-time-date-modal {
  width: 400px !important;
}

.future-wanted-time-modal {
  width: 400px !important;
}

.order-wanted-time-date-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 11px;
}
.future-wanted-time-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 11px;
}

.allergens-info-modal .mat-mdc-dialog-container {
  padding: 0;
}

.custom-dialog-two-container {
  max-width: unset !important;
}

.custom-dialog-two-container .mat-mdc-dialog-container {
  padding: 0;
}

.no-padding mat-dialog-container {
  padding: 0;
}

.rounded-border mat-dialog-container {
  border-radius: 10px;
}

.transparent-modal-backdrop {
  background-color: unset !important;
  opacity: 0 !important;
}

.basket-modal-window .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 0;
}

.custom-dialog-two-container.product-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px;
}

.info-modal {
  &-header {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3676CB;
    color: #fff;
    min-width: 300px;

    h1 {
      margin-bottom: 0;
    }
  }

  &-content {
    padding: 0;

    &-footer {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .mat-mdc-button {
        min-width: 115px;
        background-color: #3676CB;
        color: #fff;
        text-transform: uppercase;
        margin: 0 10px;
      }
    }
  }
}

.identity-modal-window .mat-mdc-dialog-surface {
  padding: 0;
  overflow: hidden;
}

.identity-modal-window .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: transparent;
  box-shadow: none;
}

.order-wanted-time-modal .mat-mdc-dialog-container {
  max-width: 470px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 0 10px;
}

@media screen and (max-width: $mobile-width-portrait) {
  .order-wanted-time-modal {
    max-width: unset !important;
  }
}

/////////////////////////////////////
// Toasts
/////////////////////////////////////

.toast-success, .toast-error, .toast-warning, .toast-info {
  opacity: 1 !important;
  z-index: 999999999;
  border-radius: $toast-border-radius !important;
}

.toast-success, .toast-info {
  background-color: $color-brand-1 !important;
}

.toast-container {
  z-index: 9999999999 !important;
}

/////////////////////////////////////
// Forms
/////////////////////////////////////


.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: $color-brand-1 !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-color: $color-brand-1 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $color-brand-1;
}

.androweb-form-group {
  border: none;
  border-left: none;

  .mat-icon {
    font-size: 19px;
    height: 19px;
    width: 19px;
    min-width: 19px;
  }

  textarea, input {
    background-color: #f5f5f5;
    border: none;
    border-left: none;
    border-radius: 6px !important;
    padding: 9px;

    &:hover, &:focus {
      background-color: #DBDBDB;
    }
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    text-overflow: ellipsis;
    width: 100%;
  }

  label {
    margin-bottom: 0;
    line-height: normal;
    font-weight: bold;
    font-size: 13px;
  }

  select {
    border-left: none;
    padding: 0;
  }
}
.androweb-form-group.with-prefix input {
  width: calc(100% - 37px);
}

.androweb-form-rule {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  margin-top: 5px;

  &-red {
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 3px;
    margin-right: 2px;
  }

  &-text {
    color: #495057;
    font-size: 12px;
    margin-bottom: 3px;
  }
}

.small-alert {
  color: #FF0000;
  font-weight: 600;
}

.wanted-time-change-button.basket-delivery-time-icon {
  cursor: pointer;
  color: #1B95E0;
  padding: 2px 8px;
  text-decoration: underline;
}

.flex {
  display: flex;
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-row-centered {
  @extend .flex-row;
  align-items: center;
  justify-content: center;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

/////////////////////////////////////
// Syncfusion overrides
/////////////////////////////////////

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus,
.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover,
.e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-today.e-selected:hover span.e-day {
  background-color: #317ab9;
  border-color: #265f91;
  color: #fff;
}

.e-calendar .e-footer-container {
  justify-content: center;
}


.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #317ab9;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day {
  background-color: #eee;
  border: 1px solid #317ab9;
  color: #fff;
}

td.e-disabled.e-today span.e-day {
  box-shadow: none !important;
}

/////////////////////////////////////

.home-page-section-group-details-text {
  H1 {
    font-size: 2.5rem;
    font-weight: 500;
  }
  
  H2 {
    font-size: 2rem;
    font-weight: 500;
  }
}

@media screen and (max-width: $tablet-width-portrait) {
  .home-page-section-group-details-text {
    line-height: initial;
  }

  .site-card-accordion .mat-content {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: $mobile-width-portrait) {
  .menu-header {
    z-index: 2;
  }

  .menu-content {
      z-index: 1;
  }

  .sticky {
    position: fixed;
    width: 100%
  }

  .siteContainer.on-menu {
    .content {
      overflow-x: unset;
    }
  }

  .sticky + .menu-content {
    padding-top: 45px;
  }

  .order-wanted-time-date-modal {
    max-width: unset !important;
    height: 100%;
  }

  #order-wanted-time-date-modal {
    height: 100%;
  }

  .siteContainer .header {
    height: 45px;
  }

  .custom-dialog-two-container.product-modal .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 0px;
  }
}

.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

mat-form-field.small {
  --mat-form-field-container-height: 40px;
  --mat-form-field-container-vertical-padding: 8px;
}

.button-request {
  display: flex;
  justify-content: center;
  padding: 4px 12px;
  color: white;
  background: $color-brand-2;
  border: 0;
  border-radius: 999px;
  white-space: nowrap;

  &:hover {
      background: $color-brand-2-mild;
  }
}